//*--------------Vistas del administrador -----------------*/
import Editions from '@/pages/admin/editions/Editions.vue'
import showEdition from '@/pages/admin/editions/showEdition.vue'
import editEdition from '@/pages/admin/editions/editEdition.vue'
import entrepreneurshipsEdition from '@/pages/admin/editions/entrepreneurships/entrepreneurshipsEdition.vue'
import newEntrepreneurshipEdition from '@/pages/admin/editions/entrepreneurships/newEntrepreneurshipEdition.vue'
import showConvocationFormEdition from '@/pages/admin/editions/entrepreneurships/show/showConvocationFormEdition.vue'
import showEntrepreneurshipsEdition from '@/pages/admin/editions/entrepreneurships/show/showEntrepreneurshipsEdition.vue'

import showConvocationFormAccepted from '@/pages/admin/editions/entrepreneurships/show/entrepreneurship/showConvocationFormAccepted.vue'
import showEntrepreneurshipAccepted from '@/pages/admin/editions/entrepreneurships/show/entrepreneurship/showEntrepreneurshipAccepted.vue'
import showTeamAccepted from '@/pages/admin/editions/entrepreneurships/show/entrepreneurship/showTeamAccepted.vue'
import showWindowAccepted from '@/pages/admin/editions/entrepreneurships/show/entrepreneurship/showWindowAccepted.vue'
import showModulesPreIncubations from '@/pages/admin/editions/entrepreneurships/show/entrepreneurship/showModulesPreIncubations.vue'
import showModulePreIncubation from '@/pages/admin/editions/entrepreneurships/show/entrepreneurship/showModulePreIncubation.vue'

// import preIncubation from'@/pages/admin/editions/stages/preIncubation/modules/Modules.vue'
import modules from'@/pages/admin/editions/stages/preIncubation/modules/Modules.vue'
import showModule from '@/pages/admin/editions/stages/preIncubation/modules/show.vue'
import newModule from '@/pages/admin/editions/stages/preIncubation/modules/newModule.vue'
import editModule from '@/pages/admin/editions/stages/preIncubation/modules/editModule.vue'

import pills from'@/pages/admin/editions/stages/preIncubation/pills/pills.vue'
import newPill from'@/pages/admin/editions/stages/preIncubation/pills/newPill.vue'
import showPill from'@/pages/admin/editions/stages/preIncubation/pills/showPill.vue'
import editPill from'@/pages/admin/editions/stages/preIncubation/pills/editPill.vue'

import modulesIncubation from'@/pages/admin/editions/stages/Incubation/modules/ModulesIncubation.vue'
import showModuleIncubation from '@/pages/admin/editions/stages/Incubation/modules/showModuleIncubation.vue'
import newModuleIncubation from '@/pages/admin/editions/stages/Incubation/modules/newModuleIncubation.vue'
import editModuleIncubation from '@/pages/admin/editions/stages/Incubation/modules/editModuleIncubation.vue'

import pillsIncubation from'@/pages/admin/editions/stages/Incubation/pills/pillsIncubation.vue'
import newPillIncubation from'@/pages/admin/editions/stages/Incubation/pills/newPillIncubation.vue'
import showPillIncubation from'@/pages/admin/editions/stages/Incubation/pills/showPillIncubation.vue'
import editPillIncubation from'@/pages/admin/editions/stages/Incubation/pills/editPillIncubation.vue'

import Entrepreneurships from '@/pages/admin/entrepreneurships/Entrepreneurships.vue'
import showEntrepreneurships from '@/pages/admin/entrepreneurships/showEntrepreneurship/showEntrepreneurships.vue'
import showEntrepreneurshipsConvocationForm from '@/pages/admin/entrepreneurships/showEntrepreneurship/showEntrepreneurshipsConvocationForm'
import showEntrepreneurshipsStartup from '@/pages/admin/entrepreneurships/showEntrepreneurship/showEntrepreneurshipsStartup'
import showEntrepreneurshipsTeam from '@/pages/admin/entrepreneurships/showEntrepreneurship/showEntrepreneurshipsTeam'
import showEntrepreneurshipsWindow from '@/pages/admin/entrepreneurships/showEntrepreneurship/showEntrepreneurshipsWindow.vue'
import showEntrepreneurshipsWindowWall from '@/pages/admin/entrepreneurships/showEntrepreneurship/showEntrepreneurshipsWindowWall.vue'

import showPreIncubationStageAdmin from '@/pages/admin/entrepreneurships/showEntrepreneurship/preIncubation/showPreIncubationStageAdmin.vue'
import showPreIncubationStageTaskAdmin from '@/pages/admin/entrepreneurships/showEntrepreneurship/preIncubation/showPreIncubationStageTaskAdmin.vue'

import showIncubationStageAdmin from '@/pages/admin/entrepreneurships/showEntrepreneurship/incubation/showIncubationStageAdmin.vue'
import showIncubationStageTaskAdmin from '@/pages/admin/entrepreneurships/showEntrepreneurship/incubation/showIncubationStageTaskAdmin.vue'

// import showEntrepreneurshipsModulePreIncubation from '@/pages/admin/entrepreneurships/showEntrepreneurship/preIncubation/showModulePreIncubation.vue'

// import showEntrepreneurshipsModulesPreIncubation from '@/pages/admin/entrepreneurships/showEntrepreneurship/preIncubation/showModulesPreIncubations.vue'


import Evaluators from '@/pages/admin/evaluator/Evaluators.vue'
import ModuleAssigned from '@/pages/admin/evaluator/moduleAssigned.vue'

import Tutors from '@/pages/admin/tutor/tutors.vue'
import EntrepreneurshipsAssignedTutors from '@/pages/admin/tutor/moduleAssigned.vue'

import Users from '@/pages/admin/users/Users.vue'
import Entrepreneurs from '@/pages/admin/entrepreneurs/Entrepreneurs.vue'

import Config from '@/pages/admin/config/Config.vue'
import AcademicUnits from '@/pages/admin/config/AcademicUnits.vue'
import Careers from '@/pages/admin/config/Careers.vue'
import VerticalWorks from '@/pages/admin/config/VerticalWorks.vue'
import EntrepreneurshipNeed from '@/pages/admin/config/EntrepreneurshipNeed.vue'
import EntrepreneurshipCategories from '@/pages/admin/config/EntrepreneurshipCategories.vue'
import Provincias from '@/pages/admin/config/Provincias.vue'
import Localidades from '@/pages/admin/config/Localidades.vue'


const adminRoutes= [
    
    {
      path: '/admin/editions',
      name: 'editions',
      component: Editions,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Ediciones',
            to: { name: 'editions' }
          }
        ]
      }
    },
    {
      path: '/admin/editions/:edition_id',
      name: 'showEdition',
      component: showEdition,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Ediciones',
            to: { name: 'editions' }
          },
          {
            text: 'Ver'
          }
        ]
      }
    },
    {
      path: '/admin/editions/:edition_id/edit',
      name: 'editEdition',
      component: editEdition,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Ediciones',
            to: { name: 'editions' }
          },
          {
            text: 'Editar'
          }
        ]
      }
    },
    {
      path: '/admin/editions/:edition_id/entrepreneurships',
      name: 'entrepreneurshipsEdition',
      component: entrepreneurshipsEdition,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Ediciones',
            to: { name: 'editions' }
          },
          {
            text: 'Emprendimientos'
          }
        ]
      }
    },
    {
      path: '/admin/editions/:edition_id/entrepreneurships/new',
      name: 'newEntrepreneurshipEdition',
      component: newEntrepreneurshipEdition,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Ediciones',
            to: { name: 'editions' }
          },
          {
            text: 'Emprendimientos',
            to: { name: 'entrepreneurshipsEdition'}
          },
          {
            text: 'Nuevo'
          }
        ]
      }
    },
    {
      path: '/admin/editions/:edition_id/convocation-form/:convocation_form_id',
      name: 'showConvocationFormEdition',
      component: showConvocationFormEdition,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Ediciones',
            to: { name: 'editions' }
          },
          {
            text: 'Emprendimientos',
            to: { name: 'entrepreneurshipsEdition' }
          },
          {
            text: 'Ver'
          }
        ]
      }
    },
    {
      path: '/admin/editions/:edition_id/entrepreneurships/:entrepreneurship_id',
      name: 'showEntrepreneurshipsEdition',
      component: showEntrepreneurshipsEdition,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Ediciones',
            to: { name: 'editions' }
          },
          {
            text: 'Emprendimientos',
            to: { name: 'entrepreneurshipsEdition' }
          },
          {
            text: 'Ver'
          }
        ]
      }
    },
    {
      path: '/admin/editions/:edition_id/entrepreneurships/:entrepreneurship_id/convocation-form/:convocation_form_id',
      name: 'showConvocationFormAccepted',
      component: showConvocationFormAccepted,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Ediciones',
            to: { name: 'editions' }
          },
          {
            text: 'Emprendimientos',
            to: { name: 'entrepreneurshipsEdition' }
          },
          {
            text: 'Ver',
            to: { name: 'showEntrepreneurshipsEdition' }
          },
          {
            text: 'Formularaio de convocatoria'
          }
        ]
      }
    },
    {
      path: '/admin/editions/:edition_id/entrepreneurships/:entrepreneurship_id/show-entrepreneurship',
      name: 'showEntrepreneurshipAccepted',
      component: showEntrepreneurshipAccepted,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Ediciones',
            to: { name: 'editions' }
          },
          {
            text: 'Emprendimientos',
            to: { name: 'entrepreneurshipsEdition' }
          },
          {
            text: 'Ver',
            to: { name: 'showEntrepreneurshipsEdition' }
          },
          {
            text: 'StartUp'
          }
        ]
      }
    },
    {
      path: '/admin/editions/:edition_id/entrepreneurships/:entrepreneurship_id/show-team/:convocation_form_id',
      name: 'showTeamAccepted',
      component: showTeamAccepted,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Ediciones',
            to: { name: 'editions' }
          },
          {
            text: 'Emprendimientos',
            to: { name: 'entrepreneurshipsEdition' }
          },
          {
            text: 'Ver',
            to: { name: 'showEntrepreneurshipsEdition' }
          },
          {
            text: 'Equipo emprendedor'
          }
        ]
      }
    },
    {
      path: '/admin/editions/:edition_id/entrepreneurships/:entrepreneurship_id/show-window/:convocation_form_id',
      name: 'showWindowAccepted',
      component: showWindowAccepted,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Ediciones',
            to: { name: 'editions' }
          },
          {
            text: 'Emprendimientos',
            to: { name: 'entrepreneurshipsEdition' }
          },
          {
            text: 'Ver',
            to: { name: 'showEntrepreneurshipsEdition' }
          },
          {
            text: 'Vidriera'
          }
        ]
      }
    },
    {
      path: '/admin/editions/:edition_id/entrepreneurships/:entrepreneurship_id/show-modules-pre-incubation',
      name: 'showModulesPreIncubations',
      component: showModulesPreIncubations,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Ediciones',
            to: { name: 'editions' }
          },
          {
            text: 'Emprendimientos',
            to: { name: 'entrepreneurshipsEdition' }
          },
          {
            text: 'Ver',
            to: { name: 'showEntrepreneurshipsEdition' }
          },
          {
            text: 'Modulos pre-incubación'
          }
        ]
      }
    },
    {
      path: '/admin/editions/:edition_id/entrepreneurships/:entrepreneurship_id/show-modules-pre-incubation/:module_pre_incubation_id',
      name: 'showModulePreIncubation',
      component: showModulePreIncubation,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Ediciones',
            to: { name: 'editions' }
          },
          {
            text: 'Emprendimientos',
            to: { name: 'entrepreneurshipsEdition' }
          },
          {
            text: 'Ver',
            to: { name: 'showEntrepreneurshipsEdition' }
          },
          {
            text: 'Modulos pre-incubación',
            to: { name: 'showModulesPreIncubations' }
          },
          {
            text: 'Ver'
          }
        ]
      }
    },
    
    {
      path: '/admin/editions/:edition_id/edit/pre-incubation',
      name: 'modules',
      component: modules,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Ediciones',
            to: { name: 'editions' }
          },
          {
            text: 'Editar',
            to: { name: 'editEdition' }
          },
          {
            text: 'Pre Incubación'
          }
        ]
      }
    },
    {
      path: '/admin/editions/:edition_id/edit/pre-incubation/:id_module',
      name: 'showModule',
      component: showModule,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Ediciones',
            to: { name: 'editions' }
          },
          {
            text: 'Editar',
            to: { name: 'editEdition' }
          },
          {
            text: 'Pre Incubación',
            to: { name: 'modules' }
          },
          {
            text: 'Ver',
          }
        ]
      }
    },
    {
      path: '/admin/editions/:edition_id/edit/pre-incubation/new-module',
      name: 'newModule',
      component: newModule,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Ediciones',
            to: { name: 'editions' }
          },
          {
            text: 'Editar',
            to: { name: 'editEdition' }
          },
          {
            text: 'Pre Incubación',
            to: { name: 'modules' }
          },
          {
            text: 'Nuevo',
          }
        ]
      }
    },
    {
      path: '/admin/editions/:edition_id/edit/pre-incubation/:id_module/edit',
      name: 'editModule',
      component: editModule,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Ediciones',
            to: { name: 'editions' }
          },
          {
            text: 'Editar',
            to: { name: 'editEdition' }
          },
          {
            text: 'Pre Incubación',
            to: { name: 'modules' }
          },
          {
            text: 'Editar',
          }
        ]
      }
    },
    {
      path: '/admin/editions/:edition_id/edit/pre-incubation/:module_id/pills',
      name: 'pills',
      component: pills,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Ediciones',
            to: { name: 'editions' }
          },
          {
            text: 'Editar',
            to: { name: 'editEdition' }
          },
          {
            text: 'Pre Incubación',
            to: { name: 'modules' }
          },
          {
            text: 'Pildoras',
          }
        ]
      }
    },
    {
      path: '/admin/editions/:edition_id/edit/pre-incubation/:module_id/pills/new',
      name: 'newPill',
      component: newPill,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Ediciones',
            to: { name: 'editions' }
          },
          {
            text: 'Editar',
            to: { name: 'editEdition' }
          },
          {
            text: 'Pre Incubación',
            to: { name: 'modules' }
          },
          {
            text: 'Pildoras',
            to: { name: 'pills' }
          },
          {
            text: 'Nueva',
           }
        ]
      }
    },
    {
      path: '/admin/editions/:edition_id/edit/pre-incubation/:module_id/pills/show/:pill_id',
      name: 'showPill',
      component: showPill,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Ediciones',
            to: { name: 'editions' }
          },
          {
            text: 'Editar',
            to: { name: 'editEdition' }
          },
          {
            text: 'Pre Incubación',
            to: { name: 'modules' }
          },
          {
            text: 'Pildoras',
            to: { name: 'pills' }
          },
          {
            text: 'Ver',
           }
        ]
      }
    },
    {
      path: '/admin/editions/:edition_id/edit/pre-incubation/:module_id/pills/edit/:pill_id',
      name: 'editPill',
      component: editPill,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Ediciones',
            to: { name: 'editions' }
          },
          {
            text: 'Editar',
            to: { name: 'editEdition' }
          },
          {
            text: 'Pre Incubación',
            to: { name: 'modules' }
          },
          {
            text: 'Pildoras',
            to: { name: 'pills' }
          },
          {
            text: 'Editar',
           }
        ]
      }
    },

    {
      path: '/admin/editions/:edition_id/edit/incubation',
      name: 'modulesIncubation',
      component: modulesIncubation,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Ediciones',
            to: { name: 'editions' }
          },
          {
            text: 'Editar',
            to: { name: 'editEdition' }
          },
          {
            text: 'Incubación'
          }
        ]
      }
    },
    {
      path: '/admin/editions/:edition_id/edit/incubation/:module_id',
      name: 'showModuleIncubation',
      component: showModuleIncubation,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Ediciones',
            to: { name: 'editions' }
          },
          {
            text: 'Editar',
            to: { name: 'editEdition' }
          },
          {
            text: 'Incubación',
            to: { name: 'modulesIncubation' }
          },
          {
            text: 'Ver',
          }
        ]
      }
    },
    {
      path: '/admin/editions/:edition_id/edit/incubation/new-module',
      name: 'newModuleIncubation',
      component: newModuleIncubation,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Ediciones',
            to: { name: 'editions' }
          },
          {
            text: 'Editar',
            to: { name: 'editEdition' }
          },
          {
            text: 'Incubación',
            to: { name: 'modulesIncubation' }
          },
          {
            text: 'Nuevo',
          }
        ]
      }
    },
    {
      path: '/admin/editions/:edition_id/edit/incubation/:module_id/edit',
      name: 'editModuleIncubation',
      component: editModuleIncubation,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Ediciones',
            to: { name: 'editions' }
          },
          {
            text: 'Editar',
            to: { name: 'editEdition' }
          },
          {
            text: 'Incubación',
            to: { name: 'modulesIncubation' }
          },
          {
            text: 'Editar',
          }
        ]
      }
    },


    {
      path: '/admin/editions/:edition_id/edit/incubation/:module_id/pills',
      name: 'pillsIncubation',
      component: pillsIncubation,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Ediciones',
            to: { name: 'editions' }
          },
          {
            text: 'Editar',
            to: { name: 'editEdition' }
          },
          {
            text: 'Incubación',
            to: { name: 'modulesIncubation' }
          },
          {
            text: 'Pildoras',
          }
        ]
      }
    },
    {
      path: '/admin/editions/:edition_id/edit/incubation/:module_id/pills/new',
      name: 'newPillIncubation',
      component: newPillIncubation,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Ediciones',
            to: { name: 'editions' }
          },
          {
            text: 'Editar',
            to: { name: 'editEdition' }
          },
          {
            text: 'Incubación',
            to: { name: 'modulesIncubation' }
          },
          {
            text: 'Pildoras',
            to: { name: 'pillsIncubation' }
          },
          {
            text: 'Nueva',
           }
        ]
      }
    },
    {
      path: '/admin/editions/:edition_id/edit/incubation/:module_id/pills/show/:pill_id',
      name: 'showPillIncubation',
      component: showPillIncubation,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Ediciones',
            to: { name: 'editions' }
          },
          {
            text: 'Editar',
            to: { name: 'editEdition' }
          },
          {
            text: 'Pre Incubación',
            to: { name: 'modulesIncubation' }
          },
          {
            text: 'Pildoras',
            to: { name: 'pillsIncubation' }
          },
          {
            text: 'Ver',
           }
        ]
      }
    },
    {
      path: '/admin/editions/:edition_id/edit/incubation/:module_id/pills/edit/:pill_id',
      name: 'editPillIncubation',
      component: editPillIncubation,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Ediciones',
            to: { name: 'editions' }
          },
          {
            text: 'Editar',
            to: { name: 'editEdition' }
          },
          {
            text: 'Incubación',
            to: { name: 'modulesIncubation' }
          },
          {
            text: 'Pildoras',
            to: { name: 'pillsIncubation' }
          },
          {
            text: 'Editar',
           }
        ]
      }
    },


    {
      path: '/admin/entrepreneurships',
      name: 'entrepreneurships',
      component: Entrepreneurships,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Emprendimientos'
          }
        ]
      }
    },
    {
      path: '/admin/entrepreneurships/:id',
      name: 'showEntrepreneurships',
      component: showEntrepreneurships,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Emprendimientos',
            to: { name: 'entrepreneurships' }
          },
          {
            text: 'Ver'
          }
        ]
      }
    },
    {
      path: '/admin/entrepreneurships/:id/convocation-form',
      name: 'showEntrepreneurshipsConvocationForm',
      component: showEntrepreneurshipsConvocationForm,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Emprendimientos',
            to: { name: 'entrepreneurships' }
          },
          {
            text: 'Ver',
            to: { name: 'showEntrepreneurships' }
          },
          {
            text: 'Formulario de la convocatoria'
          }
        ]
      }
    },
    {
      path: '/admin/entrepreneurships/:id/startup',
      name: 'showEntrepreneurshipsStartup',
      component: showEntrepreneurshipsStartup,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Emprendimientos',
            to: { name: 'entrepreneurships' }
          },
          {
            text: 'Ver',
            to: { name: 'showEntrepreneurships' }
          },
          {
            text: 'StartUp'
          }
        ]
      }
    },
    {
      path: '/admin/entrepreneurships/:id/team',
      name: 'showEntrepreneurshipsTeam',
      component: showEntrepreneurshipsTeam,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Emprendimientos',
            to: { name: 'entrepreneurships' }
          },
          {
            text: 'Ver',
            to: { name: 'showEntrepreneurships' }
          },
          {
            text: 'Integrantes'
          }
        ]
      }
    },
    {
      path: '/admin/entrepreneurships/:id/window',
      name: 'showEntrepreneurshipsWindow',
      component: showEntrepreneurshipsWindow,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Emprendimientos',
            to: { name: 'entrepreneurships' }
          },
          {
            text: 'Ver',
            to: { name: 'showEntrepreneurships' }
          },
          {
            text: 'Ver vidriera'
          }
        ]
      }
    },
    {
      path: '/admin/entrepreneurships/:id/window-wall',
      name: 'showEntrepreneurshipsWindowWall',
      component: showEntrepreneurshipsWindowWall,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Emprendimientos',
            to: { name: 'entrepreneurships' }
          },
          {
            text: 'Vidriera'
          }
        ]
      }
    },

    {
      path: '/admin/edition/:edition_id/entrepreneurships/:id/pre-incubation',
      name: 'showPreIncubationStageAdmin',
      component: showPreIncubationStageAdmin,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Emprendimientos',
            to: { name: 'entrepreneurships' }
          },
          {
            text: 'Ver',
            to: { name: 'showEntrepreneurships' }
          },
          {
            text: 'Pre Incubación'
          }
        ]
      }
    },
    

    {
      path: '/admin/entrepreneurships/:entrepreneurship_id/pre-incubation/:module_pre_incubation_id',
      name: 'showPreIncubationStageTaskAdmin',
      component: showPreIncubationStageTaskAdmin,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Emprendimientos',
            to: { name: 'entrepreneurships' }
          },
          {
            text: 'Ver',
            to: { name: 'showEntrepreneurships' }
          },
          {
            text: 'Pre incubación'
          }
        ]
      }
    },

    {
      path: '/admin/entrepreneurships/:id/incubation',
      name: 'showIncubationStageAdmin',
      component: showIncubationStageAdmin,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Emprendimientos',
            to: { name: 'entrepreneurships' }
          },
          {
            text: 'Ver',
            to: { name: 'showEntrepreneurships' }
          },
          {
            text: 'Incubación'
          }
        ]
      }
    },
    

    {
      path: '/admin/entrepreneurships/:entrepreneurship_id/incubation/:module_incubation_id',
      name: 'showIncubationStageTaskAdmin',
      component: showIncubationStageTaskAdmin,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Emprendimientos',
            to: { name: 'entrepreneurships' }
          },
          {
            text: 'Ver',
            to: { name: 'showEntrepreneurships' }
          },
          {
            text: 'incubación'
          }
        ]
      }
    },
    
    // {
    //   path: '/admin/entrepreneurships/:id/modules-incubation',
    //   name: 'showEntrepreneurshipsModulesIncubation',
    //   component: showEntrepreneurshipsModulesIncubation,
    //   meta: {
    //     auth: true,
    //     role: 'admin', 
    //     breadCrumb: [
    //       {
    //         text: 'Emprendimientos',
    //         to: { name: 'entrepreneurships' }
    //       },
    //       {
    //         text: 'Ver',
    //         to: { name: 'showEntrepreneurships' }
    //       },
    //       {
    //         text: 'Incubación'
    //       }
    //     ]
    //   }
    // },
    
    
    {
      path: '/admin/entrepreneurs',
      name: 'entrepreneurs',
      component: Entrepreneurs,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Emprendedores'
          }
        ]
      }
    },
    {
      path: '/admin/evaluators',
      name: 'evaluators',
      component: Evaluators,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Evaluadores',
          }
        ]
      }
    },
    {
      path: '/admin/evaluators/module-assigned/:evaluator_id',
      name: 'moduleAssigned',
      component: ModuleAssigned,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Evaluadores',
            to: { name: 'evaluators' }
          },
          {
            text: 'Proyectos asignados'
          }
        ]
      }
    },
    {
      path: '/admin/tutors',
      name: 'tutors',
      component: Tutors,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Tutores',
          }
        ]
      }
    },
    {
      path: '/admin/tutors/module-assigned/:tutor_id',
      name: 'entrepreneurshipsAssignedTutors',
      component: EntrepreneurshipsAssignedTutors,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Tutores',
            to: { name: 'tutors' }
          },
          {
            text: 'Proyectos asignados'
          }
        ]
      }
    },
    {
      path: '/admin/users',
      name: 'users',
      component: Users,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Usuarios',
          }
        ]
      }
    },
    {
      path: '/admin/config',
      name: 'config',
      component: Config,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Configuración'
          }
        ]
      }
    },
    {
      path: '/admin/config/academic-units',
      name: 'academicUnits',
      component: AcademicUnits,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Configuración',
            to: { name: 'config'}
          },
          {
            text: 'Unidades Académicas'
          }
        ]
      }
    },
    {
      path: '/admin/config/careers',
      name: 'careers',
      component: Careers,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Configuración',
            to: { name: 'config'}
          },
          {
            text: 'Carreras'
          }
        ]
      }
    },
    {
      path: '/admin/config/vertical-works',
      name: 'verticalWorks',
      component: VerticalWorks,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Configuración',
            to: { name: 'config'}
          },
          {
            text: 'Verticales de Trabajo'
          }
        ]
      }
    },
    {
      path: '/admin/config/entrepreneurship-needs',
      name: 'entrepreneurshipNeed',
      component: EntrepreneurshipNeed,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Configuración',
            to: { name: 'config'}
          },
          {
            text: 'Necesidades de los Emprendimientos'
          }
        ]
      }
    },
    {
      path: '/admin/config/entrepreneurship-categories',
      name: 'EntrepreneurshipCategories',
      component: EntrepreneurshipCategories,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Configuración',
            to: { name: 'config'}
          },
          {
            text: 'Categorias de los Emprendimientos'
          }
        ]
      }
    },
    {
      path: '/admin/config/provincias',
      name: 'provincias',
      component: Provincias,
      meta: {
        auth: true,
        role: 'admin', 
        breadCrumb: [
          {
            text: 'Configuración',
            to: { name: 'config'}
          },
          {
            text: 'Provincias'
          }
        ]
      }
    },
    {
      path: '/admin/config/localidades',
      name: 'localidades',
      component: Localidades,
      meta: {
        auth: true,
        role: 'admin',
        breadCrumb: [
          {
            text: 'Configuración',
            to: { name: 'config'}
          },
          {
            text: 'Localidades'
          }
        ]
      }
    }
  ]

  export default adminRoutes