<template>
    <v-row>
      <v-col cols="12">
        <v-sheet 
          width="100%"
          outlined 
          min-height="400"
        >
          <v-row class="ma-2">

            <v-col cols="12">
              <v-select
                :loading="loading"
                :items="editions"
                label="Edición"
                v-model="edition_id"
                name="editions"
                :item-text="'name'"
                :item-value="'id'"
                outlined
              ></v-select>
            </v-col>
            
            <v-col cols="12">
              <v-tabs v-model="active_tab" class="d-flex justify-center">
                <v-tab key="0" >Pre Incubación</v-tab>
                <v-tab key="1" >Incubación</v-tab>
                <v-tab key="2" >Pre aceleración</v-tab>
              </v-tabs>
            </v-col>
            
            <v-col 
              v-for="(module, id) in modules" :key="id"  
              cols="12" 
              xl="4" 
              lg="4" 
              md="6"
              sm="12" 
              xs="12"
            >
              <v-card
                v-if="!loading"
                class="pa-1 d-flex flex-column"
                height="280px"
                min-height="100%"
                color="#0B869C"
                dark
                elevation-8
                hover
                width="100%">
  
                <v-card-title class="font-weight-light">
                  #{{ module.order }}
                </v-card-title>
  
                <v-card-text>
                  <h2 class="font-weight-light white--text">{{ module.name }}</h2>
                </v-card-text>
  
                <v-spacer></v-spacer>
  
                <v-card-actions 
                  v-if="active_tab==0">
                  <v-btn
                    color="white"
                    dark
                    depressed
                    block
                    outlined
                    
                    :to="{name: 'ModulePreIncubation', params: { stage: 'pre-incubation', slug: module.slug } }">
                    Ver módulo
                  </v-btn>
                </v-card-actions>

                <v-card-actions 
                  v-if="active_tab==1">
                  <v-btn
                    color="white"
                    dark
                    depressed
                    block
                    outlined
                    disabled
                    :to="{name: 'ModuleIncubation', params: { stage: 'incubation', slug: module.slug } }">
                    Ver módulo
                  </v-btn>
                </v-card-actions>

              </v-card>
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
      
        

  
      <v-dialog
        v-model="loading"
        overlay
        persistent
        width="300"
      >
        <v-card
          color="primary"
          dark
        >
          <v-card-text>
            Cargando...
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>

    </v-row>
  </template>
  
  <script>
    import axios from 'axios';
  
    export default {
      name: 'Modules',
      data() {
        return {
          editions:[],
          edition_id:null,

          active_tab: null,

          modules:null,
          loading: true,
          slug:''
        }
      },
      watch: {
        edition_id: {
          handler() {
            // Llama a la función getEntrepreneurships() cada vez que edition_id cambie
            this.active_tab = 0
          },
          immediate: true // Para que se ejecute inmediatamente al cargar el componente
        },
        active_tab: {
          handler() {
            // Llama a la función getEntrepreneurships() cada vez que edition_id cambie
            this.loading = true
            this.getModules();
          },
          immediate: true // Para que se ejecute inmediatamente al cargar el componente
        }
      },
      methods: {
        async getEditions()
        {
          await axios.get('evaluator/editions').then(response =>{
            this.editions = response.data.data.editions
            
            if(this.editions && !this.edition_id){
              this.edition_id = this.editions[0].id
            }
          }).catch(error => {
            console.log(error);
            this.loading=false
          })
        },
        
        async getModules() {
          let current_tab = 'pre-incubation'
          switch (this.active_tab) {
            case 0:
              current_tab = 'pre-incubation'
              break;
            case 1:
              current_tab = 'incubation'
              break;
            case 2:
              current_tab = 'pre-aceleration'
              break;
            default:
              current_tab = 'pre-incubation'
              break;
          }
          if(!this.edition_id){
            this.edition_id = 1
          }
          try {
            await axios.get('evaluator/editions/'+this.edition_id+'/modules/'+current_tab).then(response => {
              this.modules = response.data.data.modules
              this.loading = false
            })
          } catch (error) {
            console.log(error);
          }
        }
      },
      created() {
        this.getEditions()
      }
    }
  </script>